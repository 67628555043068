.setting-container {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    padding: 20px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 10px lightgray;
    background-color: white;
    border-radius: 10px;
}

.section-separator {
    border-top: 1.5px solid gray;
    margin: 20px -20px; /* Adjust the margin to extend the line */
}

.reset-button {
    width: 200px;
    margin-top: 20px;
    margin-left: auto;
    color: white;
    background-color: red;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.form-select.select-input {
    width: 200px;
    margin-left: auto;
    background-color: #f2f2f2;
}

.form-switch .form-check-input.custom-switch-input {
    width: 4rem;
    height: 1.5rem;
    margin: 3px 0;
}

/* style setting */

/* .content.default {
    background-color: #f6f7f9;
}
.content.light {
    background-color: white;
}
.content.dark {
    background-color: #181818;
}

.textarea-bg-theme.default {
    background-color: white;
}
.textarea-bg-theme.light {
    background-color: white;
}
.textarea-bg-theme.dark {
    background-color: #333333;
} */

.feedback-font-size.small {
    font-size: 14px;
}
.feedback-font-size.medium {
    font-size: 16px;
}
.feedback-font-size.large {
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .setting-container {
        width: 100vw;
        margin-top: 0;
        padding-top: 8rem;
        border: none;
        background-color: #f6f7f9;
        box-shadow: none;
    }

    .form-select.select-input {
        width: 150px;
    }

    .reset-button {
        width: 150px;
    }
}
