.contact-container {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 10px lightgray;
    background-color: white;
    border-radius: 10px;
}

.contact-textarea-size {
    width: 100%;
    height: 250px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
}

.contact-input {
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .contact-container {
        width: 100vw;
        margin-top: 0;
        padding-top: 8rem;
        border: none;
        background-color: #f6f7f9;
        box-shadow: none;
    }
}
