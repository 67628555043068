/* .footer {
    font-size: 14px;
    height: 40px;
    background-color: #14213d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 5;
    width: 100%;
}

.footer p {
    margin: 0;
} */

.footer {
    font-size: 14px;
    height: 80px;
    background-color: #14213d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 5;
    width: 100%;
}

.footer p {
    margin: 0;
}

.footer a {
    color: lightgray;
    text-decoration: none;
    transition: color 0.1s ease;
}

.footer span {
    color: lightgray;
}

.footer a:hover {
    color: white;
}

.footer-links {
    margin-bottom: 10px; /* Add some space between the links and the text */
}
