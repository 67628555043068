/* misc */
.placeholder-color option:disabled {
    color: #cccccc;
}

#genderSelect:not(:focus) {
    color: #ef2626; /* 未選択時の色 */
}

#genderSelect:focus,
#genderSelect:not([value=""]) {
    color: initial; /* 選択時の色 */
}
