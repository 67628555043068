.basic-table {
    border-collapse: collapse;
    width: 100%;
}

.basic-table th,
.basic-table td {
    border: 1px solid lightgray;
    padding: 7px;
}

.h3-marker::marker {
    font-size: 1.5rem;
}

.normal-letter {
    font-size: 1rem;
    display: inline;
}

.fade-out {
    position: relative;
    overflow: hidden;
}

.fade-out::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.premium-notice {
    text-align: center;
    color: red;
}

.premium-content-prompt {
    background-color: rgba(255, 255, 255, 0.8); /* A white "fog" effect */
    text-align: center;
}

.premium-content-prompt p {
    margin-bottom: 10px;
}

.premium-content-prompt button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
}

.premium-content-prompt button:hover {
    background-color: #0056b3;
}
