.body {
    height: 100%;
}
.large-font {
    font-size: 4.5rem;
}

.plan-selection {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
}

.card-body-container {
    display: flex;
    flex-direction: column;
}

.my-btn {
    margin-top: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* li {
    position: relative;
    line-height: 3;
} */

.checkSymbol {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: blue;
    border-radius: 50%;
}

.checkMark {
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.card-effect {
    transform: scale(0.9);
    transition: all 0.3s ease;
}

.card-effect:hover {
    transform: scale(0.93);
    background-color: #fcfcfc;
}

.plan-card-container:first-child {
    margin-top: 0rem;
}

.plan-card-container:last-child {
    margin-bottom: 0rem;
}

@media screen and (max-width: 768px) {
    .plan-card-contaier:first-child {
        margin-top: 1rem !important;
    }

    .plan-card-container:last-child {
        margin-bottom: 3rem !important;
    }
}
