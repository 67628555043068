/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 16px;
    top: 32px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    /* background: #373a47; */
    background: lightgray;
    height: 10% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    /* margin-top: 10px; */
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    /* background: #373a47; */
    background: white;
    /* padding: 2.5em 1.5em 0; */
    padding-top: 1.5rem;
    padding-left: 15px;
    font-size: 16px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
    display: inline-block;
    padding-bottom: 36px;
    text-decoration: none;
    color: #555555;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
