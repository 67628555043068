/* @media screen and (max-width:768px) {
    .top-adjust {
        margin-top: 5rem;
    }

    img {
        max-width: 360px;
    }
} */

.li-height-3 {
    line-height: 3rem;
}
