@font-face {
    font-family: "ColfaxAI";
    src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2) format("woff2"),
        url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ColfaxAI";
    src: url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff2) format("woff2"),
        url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
}

body,
input {
    font-size: 16px;
    line-height: 24px;
    color: #353740;
    font-family: "ColfaxAI", Helvetica, sans-serif;
}

::placeholder {
    color: #8e8ea0;
    opacity: 1;
}

/* button[type="submit"] {
  color: #fff;
  background-color: #10a37f;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
} */

.feedback-font-size {
    font-size: 16px;
}

.textarea-container {
    position: relative;
}

.textarea-border-shape {
    border-radius: 10px;
    border: solid 1px rgba(0, 0, 0, 0.176);
}

.textarea-border-shadow {
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.25) !important;
}

#essay-counter,
#recommendation-counter,
#resume-counter {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.result {
    font-weight: bold;
    margin-top: 40px;
}

.textarea-size {
    height: 300px;
    width: 750px;
}

#test-prompt {
    height: 200px;
}

.emphasized-text {
    position: relative;
    display: inline;
}

.emphasized-text::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 0, 0.3); /* Less prominent yellow color with 30% opacity */
    box-shadow: 0px 0px 5px rgba(255, 255, 0, 0.3); /* Less blur */
}

.flex-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#spinner {
    display: none;
}

.center-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.radio-container {
    display: flex;
    justify-content: space-between;
    max-width: 750px;
    /* margin: 20px auto;
  padding: 0 10px; */
}

.sample-text-container {
    position: absolute;
    top: -55px;
    right: 10px;
}

.sample-button {
    background-color: #ff4081;
    /* background-color: #f39c12; */
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
}

/* responsive */

@media screen and (max-width: 768px) {
    .textarea-size {
        width: 360px;
    }

    .sample-text-container {
        top: 260px;
    }
}
