/* for smartphone */

header {
    width: 100%;
    position: fixed;
    z-index: 1;
}

.image-center {
    display: flex;
    justify-content: center;
}

.nav-sp-shadow {
    box-shadow: 0 10px 15px -5px white;
}

.nav-sp {
    width: 360px;
}

.polymath-logo-sp {
    width: 250px;
}

/* for pc  */

.polymath-logo {
    width: 250px;
}

.navbar-pc {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbar-font-size {
    font-size: 14px;
}

.navbar-color-gray {
    color: gray;
}

.navbar-color-light-gray {
    color: lightgray;
    transition: color 0.1s ease;
}

.navbar-color-light-gray:hover {
    color: white;
}

.navbar-pc-wrapper {
    display: flex;
    justify-content: center;
    box-shadow: 0 8px 15px -5px lightgrey;
}

.navbar-width {
    width: 950px;
}

.nav-link-decoration {
    text-decoration: none;
}

.loginImgStyle img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50% !important;
    box-sizing: border-box;
    /* transition: outline 0.3s ease;  */
}

.loginImgStyle {
    border-radius: 50% !important;
    border: 2px solid transparent !important;
    box-sizing: border-box;
}

.loginImgStyle img:hover {
    border-radius: 50% !important;
    outline: 2px solid #e8e8e8 !important;
    outline-offset: -2px;
    /* border: 2px solid #E8E8E8 !important; */
    background-color: lightgray;
}
